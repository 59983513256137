import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from '../../utils/Footer'
import Header from '../../utils/Header'

const TermsAndCondition = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const onClickAgree=()=>{
        navigate('/');
      }
  return (
    <div>
         <Header/>
         <div style={{justifyContent:"flex-start",alignItems:"flex-start"}}>
<div style={{backgroundColor:"white",marginLeft:"10%",marginRight:"10%",marginTop:"5%",marginBottom:"5%",padding:"5%",textAlign:"left",fontSize:14}}>
<h3 style={{fontSize:24,fontWeight:"800",margin:50}}><u>Terms and conditions</u></h3>
<p style={{margin:25}}>SmartwatchIndia is a website to provide a better service to online customers for the best watch available on the internet, we make it easy for a user with our website to find the price difference at different e-commerce sites. Where users can check the price of the same product on our website.</p>
<p style={{margin:25}}>This website is operated by Smartwatchindia Throughout the site, the terms “we”, “us” and “our” refer to Smartwatchindia. Smartwatchindia offers this website, including all information, available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>
<p style={{margin:25}}>All the data you see on our platform are not part of us, we use all the data that is related to particular affiliate providers, and we are not responsible for any wrong content available, if you found any wrong information you can report us at productneuan@gmail.com We try to make correct information available for the end user.</p>
<p style={{margin:25}}>By visiting our site, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”) These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
<p style={{margin:25}}>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
<p style={{margin:25}}>We are not responsible if the information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. Any reliance on the material on this site is at your own risk.
This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
<p style={{margin:25}}>Prices for our products are subject to change without notice.</p>
<p style={{margin:25}}>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
<p style={{margin:25}}>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
<p style={{margin:25}}>All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
<p style={{margin:25}}>Certain content, products and services available via our Service may include materials from third-parties.</p>
<p style={{margin:25}}>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
<p style={{margin:25,fontWeight:"800",color:"black"}}>By using our website Smartwatchindia you agree to the below lines</p>
<ul>
  <li style={{marginTop:25}}>In case of any damage to a product or received an incorrect product we are not responsible for your product.</li>
  <li style={{marginTop:25}}>We make sure to provide the correct information as possible to a user with our secured platform.</li>
  <li style={{marginTop:25}}>All the links you use on our platform are Affiliate links of different Programs.</li>
  <li style={{marginTop:25}}>As you use this website you agree to all our policies and</li>
  <li style={{marginTop:25}}>All the links you use on our platform are Affiliate links of different Programs by using our website you agree that you have no problem buying any product through our website.</li>
  <li style={{marginTop:25}}>“Smartwatchindia is a participant in the amazon associate program, an affiliate advertising program designed to provide a meaning for sites to earn advertising fees by advertising and linking to Amazon.in”</li>
</ul> 
<p style={{margin:25}}>Just to be a secure platform we don't collect any user data you can browse any products you like and buy through our platform.</p>
<p style={{margin:25}}>By using this website (Smartwatch. in) you agree to all the terms and conditions</p>
<p style={{margin:25,fontWeight:"800",color:"black"}}>Contact Information</p>
<p style={{margin:25}}>Questions about the Terms of Service should be sent to us at productneuan@gmail.com
</p>
<div style={{display:"flex",flexWrap:"wrap"}}>
<div style={{flex:3}}></div>
<button onClick={onClickAgree} style={{backgroundColor:"#000000",borderRadius:25,color:"white",padding:10,alignSelf:"flex-end",flex:1}}>Agree</button>
</div>
</div>
        </div>
        <Footer/>
    </div>
  )
}

export default TermsAndCondition