import './App.css';
import HomePage from './components/Home/HomePage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllProducts from './components/AllProducts/AllProducts';
import AboutUs from './components/AboutUs/AboutUs';
import TermsAndCondition from './components/TermsAndCondition/TermsAndCondition';
import ReactGA from "react-ga"
// G-PZX5GE2S41


const TRACKING_ID = "G-PZX5GE2S41";
ReactGA.initialize(TRACKING_ID)
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/allproducts" element={<AllProducts/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/termsandcondition" element={<TermsAndCondition/>} />
      </Routes>
    </BrowserRouter>
     {/* <Link to="/blogs">Blogs</Link> */}
    </div>
  );
}

export default App;
