import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from '../../utils/Footer'
import Header from '../../utils/Header'

const AboutUs = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const onClickContinueShopping=()=>{
        navigate('/');
      }
  return (
    <div>
        <Header/>
        <div style={{justifyContent:"flex-start",alignItems:"flex-start"}}>
<div style={{backgroundColor:"white",marginLeft:"10%",marginRight:"10%",marginTop:"5%",marginBottom:"5%",padding:"5%",textAlign:"left",fontSize:14}}>
<h3 style={{fontSize:24,fontWeight:"800",margin:50}}><u>About us</u></h3>
<p style={{margin:25}}>SmartwatchIndia is a website to provide a better service to online customers for the best watch available on the internet, we make it easy for a user with our website to find the price difference at different e-commerce sites. Where users can check the price of the same product on our website.</p>
<p style={{margin:25}}>The website is designed to provide a better experience for a user to find the type of watch that suits them in a short time. We provide detailed content for the watch available online.</p>
<p style={{margin:25}}>We make it easy for the user to find all the types of watches based on categories and Brands. We have created a list of brands and their models with better information available to give easy access to the user.</p>
<p style={{margin:25}}>The website has been designed in keeping the user experience in mind to give a piece of correct information.</p>
<p style={{margin:25}}>We write a blog on the particulate watch and its models you can check them on our Blog page</p>
<p style={{margin:25}}>We have created categories for different types of watches available</p>
<ul style={{margin:25}}>
  <li>Best selling watch</li>
  <li>Smart watch</li>
  <li>Analog watch</li>
  <li>Apple Watch</li>
  <li>Budget-friendly watch</li>
  <li>swiss watch</li>
  <li>Digital watch</li>
</ul> 
<p style={{margin:25}}>“Smartwatchindia is a participant in the amazon associate program, an affiliate advertising program designed to provide a meaning for sites to earn advertising fees by advertising and linking to Amazon.in
All the links available are affiliate links.”</p>
<div style={{display:"flex",flexWrap:"wrap"}}>
<div style={{flex:3}}></div>
<button onClick={onClickContinueShopping} style={{backgroundColor:"#000000",borderRadius:25,color:"white",padding:10,alignSelf:"flex-end",flex:1}}>Continue shopping</button>
</div>
</div>
        </div>
        <Footer/>
    </div>
  )
}

export default AboutUs