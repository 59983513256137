import { useNavigate } from "react-router-dom";

function Footer() {

  const navigate = useNavigate();

  const onClickAboutUs=()=>{
    navigate('/aboutus');
  }
  const onClickTermsAndCondition=()=>{
    navigate('/termsandcondition');
  }
  return (
    <div style={{backgroundColor:"#000000",height:250,display:"flex",justifyContent:"center",alignItems:"center"}}>
    <div style={{flex:2,color:"white"}}></div>
    <div style={{flex:1,color:"white"}}>
<div style={{color:"white",fontWeight:"700",fontSize:18,padding:10,textAlign:"left"}}>Watch75</div>
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>Home</div>
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
  <a style={{textDecoration:"none"}} onClick={onClickAboutUs} >About us</a></div>
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
<a style={{textDecoration:"none"}} onClick={onClickTermsAndCondition} >Privacy and policy</a></div>
    </div>
    <div style={{flex:1,color:"white"}}>
    <div style={{color:"white",fontWeight:"700",fontSize:18,padding:10,textAlign:"left"}}>Top Brands</div>
    <a style={{textDecoration:"none"}} href="https://www.amazon.in/s?k=Rolex+watch&i=watches&camp=3638&creative=24630&linkCode=ur2&linkId=d692dd4df44af5489103d43c8f991eef&tag=watch75-21">
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
  Rolex</div>
  </a>
    <a style={{textDecoration:"none"}} href="https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=c9c7d78923acc59bd23b05a9e0838f96&camp=3638&creative=24630&index=watches&keywords=Apple%20watch">
    <div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
      Apple watch
      </div>
      </a>
      
  <a style={{textDecoration:"none"}}  href="https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=9ef1581fdad42e8dc035c13ba794ba68&camp=3638&creative=24630&index=watches&keywords=Smart%20watch">
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
    Smart watch
    </div>
    </a>
    </div>
    <div style={{flex:1,color:"white"}}>
    <div style={{color:"white",fontWeight:"700",fontSize:18,padding:10,textAlign:"left"}}>Best Selling</div>
      <a style={{textDecoration:"none"}} href="https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=ae9a203197c8bb3e108316e0b0bc7761&camp=3638&creative=24630&index=watches&keywords=Noise%20watch">
    <div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
        Noise smart watch
        </div>
        </a>
  <a style={{textDecoration:"none"}} href='https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=6f796a4f7fe234b0a0a5cb6480f38456&camp=3638&creative=24630&index=watches&keywords=Titan%20watch'>
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
    Titan
    </div>
    </a>
  <a style={{textDecoration:"none"}} href="https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=be7d1ca88b3800e801011a8c5c1fa20c&camp=3638&creative=24630&index=watches&keywords=Fastrack%20watch">
<div style={{color:"white",fontWeight:"400",fontSize:14,padding:10,textAlign:"left"}}>
    Fastrack
    </div>
    </a>
    </div>
    <div style={{flex:2,color:"white"}}></div>
    </div>
  );
}

export default Footer;
