import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Banner1 from "../images/Banner1.png"
import Banner2 from "../images/Banner2.png"
import Banner3 from "../images/Banner3.png"
import Banner4 from "../images/Banner4.png"
import Banner5 from "../images/Banner5.png"

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./StyleCarousel.css";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";

 function CustomCarousel() {

  const[bannerData,setBannerData]=useState(
    [
      {bannerImage:Banner1,link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=87426527f5ea19f01718d4270bf68f36&camp=3638&creative=24630&index=watches&keywords=watch%20offers"},
      {bannerImage:Banner2,link:"https://www.amazon.in/s?k=Apple+watch&i=watches&camp=3638&creative=24630&linkCode=ur2&linkId=3efc69e9f8b53de5aa2a4dba38643daf&tag=watch75-21"},
      {bannerImage:Banner3,link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=48e31d8d3f5155af6ef0106f27e02421&camp=3638&creative=24630&index=watches&keywords=Samsung%20watch"},
      {bannerImage:Banner4,link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=9ef1581fdad42e8dc035c13ba794ba68&camp=3638&creative=24630&index=watches&keywords=Smart%20watch"},
      {bannerImage:Banner5,link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=28dd60ffb7aa99633a6536914aee4439&camp=3638&creative=24630&index=watches&keywords=Budget%20friendly%20watch%22%3EBudget%20friendly%20watch"},
    ])
  return (
    <>
      <Swiper
        // spaceBetween={30}
        style={{width:"90%"}}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"  
      >
        {bannerData.map((item,index)=>{
          return(
            <SwiperSlide>
  <a href={item.link} target="blank" key={index}>
 <img src={item.bannerImage} style={{height:300}}/>
</a>
</SwiperSlide>
          )
        })}
      </Swiper>
    </>
  );
}

export default CustomCarousel;
