import React,{useState,useEffect} from "react";
import './HomePage.css';
import Footer from "../../utils/Footer";
import AppleWatchLogo from "../../images/apple_watch_logo.png"
import BreitlingWatchLogo from "../../images/breitling_logo.png"
import CartierWatchLogo from "../../images/cartier_logo.png"
import FastrackWatchLogo from "../../images/fastrack_logo.png"
import FossilWatchLogo from "../../images/fossil_logo.png"
import MiWatchLogo from "../../images/mi_logo.png"
import OnePlusWatchLogo from "../../images/oneplus_logo.png"
import PumaWatchLogo from "../../images/puma_logo.png"
import RolexWatchLogo from "../../images/rolex_logo.png"
import SamsungWatchLogo from "../../images/samsung_watch_logo.png"
import SonataWatchLogo from "../../images/sonata_logo.png"
import TitanWatchLogo from "../../images/titan_logo.png"
import CustomCarousel from "../../utils/Carousel";
import Header from "../../utils/Header";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga"

function HomePage(props) {
  const navigate = useNavigate();
  const[topBrandsData,setTopBrandsData]=useState([
    {brandImage:RolexWatchLogo,Link:"https://www.amazon.in/s?k=Rolex+watch&i=watches&camp=3638&creative=24630&linkCode=ur2&linkId=d692dd4df44af5489103d43c8f991eef&tag=watch75-21"},
    {brandImage:TitanWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=6f796a4f7fe234b0a0a5cb6480f38456&camp=3638&creative=24630&index=watches&keywords=Titan%20watch"},
    {brandImage:AppleWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=c9c7d78923acc59bd23b05a9e0838f96&camp=3638&creative=24630&index=watches&keywords=Apple%20watch"},
    {brandImage:CartierWatchLogo,Link:""},
    {brandImage:FossilWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=45fd201d9b2bc9cdccd2b15e4b2c67c9&camp=3638&creative=24630&index=watches&keywords=Fossil%20watch"},
    {brandImage:BreitlingWatchLogo,Link:"https://www.amazon.in/s?k=Breitling+watch&i=watches&camp=3638&creative=24630&linkCode=ur2&linkId=02bd39cd5b427c1c41dac720b0f60027&tag=watch75-21"},
    {brandImage:PumaWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=5ca0c231cc7526009b83d4dba09ea1d6&camp=3638&creative=24630&index=watches&keywords=puma%20watch"},
    {brandImage:OnePlusWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=8c36e9c61c7377e2a31b8c0bbb1b8d9b&camp=3638&creative=24630&index=watches&keywords=Oneplus%20watch"},
    {brandImage:FastrackWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=be7d1ca88b3800e801011a8c5c1fa20c&camp=3638&creative=24630&index=watches&keywords=Fastrack%20watch"},
    {brandImage:MiWatchLogo,Link:"https://www.amazon.in/s?k=Mi+watch&i=watches&camp=3638&creative=24630&linkCode=ur2&linkId=2b0b28c9f87219377ac5b1d299c14462&tag=watch75-21"},
    {brandImage:SamsungWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=ca47f5cfa1361513352a86166a1f751a&camp=3638&creative=24630&index=watches&keywords=Samsung%20galaxy%20watch%22%3ESamsung%20galaxy%20watch"},
    {brandImage:SonataWatchLogo,Link:"https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=fe20ae7f4498b251cc1aab69191afb67&camp=3638&creative=24630&index=watches&keywords=sonata%20watch"},
  ])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    window.scrollTo(0, 0);
  }, []);
 
  const onClickBestSelling=()=>{
    navigate('/allproducts',{state:{heading:"Best Selling"}});
  }
  const onClickSmartWatch=()=>{
    navigate('/allproducts',{state:{heading:"Smart Watch"}});
  }
  const onClickAnologWatch=()=>{
    navigate('/allproducts',{state:{heading:"Analog Watch"}});
  }
  const onClickAppleWatch=()=>{
    navigate('/allproducts',{state:{heading:"Apple Watch"}});
  }
  const onClickBudgetFriendly=()=>{
    navigate('/allproducts',{state:{heading:"Budget Friendly"}});
  }
  const onClickSwissWatch=()=>{
    navigate('/allproducts',{state:{heading:"Swiss Watch"}});
  }
  const onClickDigitalWatch=()=>{
    navigate('/allproducts',{state:{heading:"Digital Watch"}});
  }
  
 
 
    return (
      <div style={{backgroundColor:"#F5F5F5"}}>
        {/* Header */}
         <Header/>
         {/* Banner */}
<div style={{margin:20}}>
       <div >
       <CustomCarousel/>
       </div>
       </div>


{/* Best Selling */}
       <div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Best Selling</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
    <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickBestSelling}
  >View all</a> {'>'} </div>
        </div>
        <div style={{display:"flex",flexWrap:"wrap"}}>
<div style={{margin:35}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>
</div>

</div>

</div>

{/* Top Brands */}
<div>
<div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Top Brands</div>
  {/* <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>View all {'>'} </div> */}
</div>
<div style={{margin:40,display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
  {topBrandsData.map((item,index)=>{
    return(
      <a href={item.Link} key={index}>
        <img src={item.brandImage} alt="img" style={{flex:1,height:100,width:"90%",margin:20}}/>
      </a>
    )
  })}
   
</div>
<div>


</div>
</div>

{/* Smart Watch */}
<div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Smart Watch</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
  <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickSmartWatch}
  >View all</a> {'>'} 
   </div>
        </div>
<div style={{margin:40,display:"flex"}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>
</div>
</div>

{/* Anolog Watch */}
<div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Analog Watch</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
  <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickAnologWatch}
  >View all</a> {'>'} 
  </div>
        </div>
<div style={{margin:40,display:"flex"}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>
</div>
</div>

{/* Apple Watch */}
<div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Apple Watch</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
  <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickAppleWatch}
  >View all</a> {'>'} 
  </div>
        </div>
<div style={{margin:40,display:"flex"}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>

</div>
</div>

{/* Budget Friendly Watch */}
<div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Budget Friendly</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
  <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickBudgetFriendly}
  >View all</a> {'>'} 
  </div>
        </div>
<div style={{margin:40,display:"flex"}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>

</div>
</div>

{/* Swiss Watch */}
<div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Swiss watch</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
  <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickSwissWatch}
  >View all</a> {'>'} 
  </div>
        </div>
<div style={{margin:40,display:"flex"}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>

</div>
</div>

{/* Digital Watch */}
<div>
        <div style={{display:"flex",marginLeft:20,marginRight:20}}>
  <div style={{flex:1,textAlign:"left",marginLeft:20,fontWeight:"800",fontSize:24}}>Digital Watch</div>
  <div style={{flex:1,textAlign:"right",marginRight:20,fontWeight:"800",fontSize:16}}>
  <a style={{textDecoration:"none",color:"black"}} 
    onClick={onClickDigitalWatch}
  >View all</a> {'>'} 
  </div>
        </div>
<div style={{margin:40,display:"flex"}}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src="http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531" alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>boAt Xtend Smartwatch</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>

</div>
</div>


{/* Subscribe With Email */}
       <div style={{backgroundColor:"#FFCC01",display:"flex",alignItems:"center",margin:40,flexWrap:"wrap",padding:30,borderRadius:8}}>
        <div style={{flex:1,color:"white",fontWeight:"800",fontSize:40}}>Subscribe to get the latest deals and offers</div>
        <div style={{flex:1}}>
          <div style={{padding:15}}>
          <input style={{backgroundColor:"white",width:"min(80%, 340px)",height:40,borderRadius:25,border:"none",paddingLeft:20,fontSize:16,fontWeight:"600" }} placeholder="Enter your email"></input>
          </div>
          <div style={{padding:15,alignItems:"flex-start"}}>
          <button style={{backgroundColor:"#000000",width:180,height:50,borderRadius:25,border:"none",color:"white",fontWeight:"800",fontSize:16 }}>Submit</button>
          </div>
        </div>


</div>

{/* Footer */}
<Footer/>

      </div>
    );
  }
  
  export default HomePage;