import React from 'react'
import "./StyleHeader.css"
import  Logo from "../images/Logo.svg";

const Header = () => {
  return (
    <div className="header_style">
    <div className="header_time_style">
     <img src={Logo} alt="SVG as an image" style={{width:80,height:80}}/> 
     </div>
     <div style={{flex:6}}></div>
     <div className="header_text_style"><a href="https://www.amazon.in/b?_encoding=UTF8&tag=watch75-21&linkCode=ur2&linkId=58e93568925758f8a9989a593a67dddf&camp=3638&creative=24630&node=1375486031" style={{color:"white",textDecoration:"none"}}>Best watchs </a> </div>
     <div className="header_text_style"><a href="https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=e4ed26efeda7f8f2efcc39a962419dca&camp=3638&creative=24630&index=watches&keywords=Smart%20watch" style={{color:"white",textDecoration:"none"}}>Smart watchs </a></div>
     <div className="header_text_style"><a href="https://www.amazon.in/gp/search?ie=UTF8&tag=watch75-21&linkCode=ur2&linkId=37f567df878c6eedde5290f27eb964f8&camp=3638&creative=24630&index=watches&keywords=Trending%20watch" style={{color:"white",textDecoration:"none"}}>Trending</a></div>
     <div className="header_text_style"><a href="https://medium.com/programming-essentials/how-to-create-a-digital-clock-with-react-hooks-aa30f76cfe3f" style={{color:"white",textDecoration:"none"}}>Blog</a></div>
    </div>
  )
}

export default Header;