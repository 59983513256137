import React, { useState,useEffect } from 'react'
import Footer from '../../utils/Footer'
import Header from '../../utils/Header'
import {useLocation} from 'react-router-dom';

const AllProducts = (props) => {
    const location = useLocation();
    const[allProducts,setAllProducts]=useState(
        [
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
            {imageLink:"http://cdn.shopify.com/s/files/1/0057/8938/4802/products/main-images.png?v=1655198531",name:"boAt Xtend Smartwatch",description:"Alexa built-in Voice Assistant that sets reminders, alarms and answers questions from...",link:""},
        ])

        useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
  return (
    <div style={{backgroundColor:"#F5F5F5"}}>
        <Header/>
        <div style={{width:"100%",backgroundImage:"linear-gradient(to right,blue, #FC91F8)",display:"flex",justifyContent:"flex-start",alignItems:"flex-start",alignSelf:"flex-start"}}>
            <div style={{fontWeight:"800",fontSize:72,padding:60}}>{location.state.heading}</div>
        </div>

         {/* All Products List */}
        <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
            {allProducts.map((item,index)=>{
                return(
<div style={{margin:20}} key={index}>
<div style={{height:390,width:310,elevation:5,backgroundColor:"white",borderRadius:8}}>
 <div>
<img src={item.imageLink} alt="SVG as an image" style={{width:220,height:220,marginTop:15}}/> 
</div>
<div style={{fontSize:16,fontWeight:"700",margin:10,textAlign:"left"}}>{item.name}</div>
<div style={{fontSize:12,fontWeight:"400",margin:10,textAlign:"left",color:"#575757"}}>{item.description}</div>
<div style={{margin:15,display:"flex"}}> 
<div style={{flex:1}}></div>
<div style={{flex:1}}><button style={{backgroundColor:"#000000",width:140,height:48,borderRadius:25,border:"none",color:"white",fontWeight:"700",fontSize:12 }}>Know more</button></div>
</div>
</div>
</div>
                )
            })}

</div>
        <Footer/>
    </div>
  )
}

export default AllProducts